// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-become-a-patient-index-copy-js": () => import("./../../../src/pages/become-a-patient/index copy.js" /* webpackChunkName: "component---src-pages-become-a-patient-index-copy-js" */),
  "component---src-pages-become-a-patient-index-js": () => import("./../../../src/pages/become-a-patient/index.js" /* webpackChunkName: "component---src-pages-become-a-patient-index-js" */),
  "component---src-pages-blogs-news-index-js": () => import("./../../../src/pages/blogs/news/index.js" /* webpackChunkName: "component---src-pages-blogs-news-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-contact-us-index-old-js": () => import("./../../../src/pages/contact-us/index-old.js" /* webpackChunkName: "component---src-pages-contact-us-index-old-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-index-js": () => import("./../../../src/pages/learn/index.js" /* webpackChunkName: "component---src-pages-learn-index-js" */),
  "component---src-pages-not-close-index-js": () => import("./../../../src/pages/not-close/index.js" /* webpackChunkName: "component---src-pages-not-close-index-js" */),
  "component---src-pages-rewards-index-js": () => import("./../../../src/pages/rewards/index.js" /* webpackChunkName: "component---src-pages-rewards-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test/index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-test-template-js": () => import("./../../../src/templates/test-template.js" /* webpackChunkName: "component---src-templates-test-template-js" */)
}

